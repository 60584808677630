import { useMutation } from "@apollo/client";
import { MutationResult } from "@apollo/client/react/types/types";
import { FetchResult } from "@apollo/client/link/core/types";
import { DocumentNode } from "graphql";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { MutationOptions } from "@apollo/client/core/watchQueryOptions";
import {
  InputOptionsType,
  useParsedMutationReturnType,
} from "./useParsedMutationReturnType";

export function useParsedMutation<TInput, TOutput, TData, TVariables>({
  mutation,
  parseVariables,
  parseData,
}: Props<TInput, TOutput, TData, TVariables>): useParsedMutationReturnType<
  TInput,
  TOutput
> {
  const [mutate, mutationTuple] = useMutation<TData, TVariables>(mutation);

  const parsedMutate: (
    input: TInput,
    options?: InputOptionsType
  ) => Promise<FetchResult<TOutput>> = async (
    input: TInput,
    options?: InputOptionsType
  ) => {
    const variables = parseVariables(input);
    const { data, ...otherResults } = await mutate({
      ...options,
      variables,
    });

    let output: TOutput | undefined;

    if (data) {
      output = parseData(data, input);
    }

    return { ...otherResults, data: output };
  };

  const { data, ...otherMutationTupleValues } = mutationTuple;

  const mutationResult: MutationResult<TOutput> = {
    ...otherMutationTupleValues,
    data: undefined,
  };

  return [parsedMutate, mutationResult];
}

interface Props<TInput, TOutput, TData, TVariables> {
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>;
  parseVariables: (input: TInput) => TVariables;
  parseData: (data: TData, input: TInput) => TOutput;
}

export type OptionsType<TData, TInput> = Omit<
  MutationOptions<TData, TInput>,
  "mutation"
>;
